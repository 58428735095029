.App {
  text-align: center;
}

.app-container {
  align-items: center;
  justify-content: center;
  background-color: #61dafb;
  height: 1000;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
  background-color: #1d1d1d;
}

.Image {
  height: auto;
  width: auto;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 100px;
  color: white;
  font-family: "Alumni Sans Pinstripe";

}

@media only screen and (max-width: 600px) {
  .App-header {
    font-size: 50px;
  }

  .App {
    text-align: none;
  }
}

.Text {
  margin-top: 1000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}